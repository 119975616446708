@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

body{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.logo {
  position: absolute;
  float: left;
  z-index: 2;
  /* margin-left: 5rem; */
}

.logo>img {
  max-width: 30vw;
  padding-left: 10vw;
}

.video {
  position: fixed;
  z-index: 1;
}

.video>video {
  z-index: 1;
  width: 100vw;
  opacity: 0.5;
  min-width: 60rem;
}

.video> .photo {
    width: 100vw;
    opacity: 0.5;
}

.body {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  z-index: 2;
  opacity: 1;
  float: left;
  top: 50vh;
  position: relative;
  background: white;
  width: 100vw;
  height: 100%;
  min-height: 50vh;
}

.menu {
  padding: 1rem;
  box-shadow: grey 0px 0px 16px;
}

div.menu>a {
  margin: 1rem;
  color: gray;
  text-decoration: none;
}

div.menu>a.selected {
  font-weight: bold;
  color: gray;
}

div.menu>a:hover {
  text-decoration: underline;
  color: gray;
}

div.menu>a:visited {
  color: gray;
}

.section {
  text-align: left;
  margin-left: 25vw;
  width: 50vw;
  padding-bottom: 4rem;
}

.home {
  width: 50vw;
  line-height: 2rem;
}


.corpo-clinico {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 50vw;
}

.corpo-clinico>div {
  width: 25vw;
}

@media (max-width: 768px) {

  .body {
    top: 25vh;
  }
  .corpo-clinico>div {
    width: 50vw;
  }

}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #f6f6f6;
  width: 100vw;
  text-align: end;
  padding: 1rem;
  font-size: 12px;
}
